<template>
	<PageLayout :title="$t('education.education')" large-width>
		<div v-if="loading" class="loading-wrapper">
			<Loader />
		</div>
		<div v-if="error !== null && !loading">
			{{ error }}
		</div>
		<div v-if="error === null && !loading" class="education-cards">
			<EducationCategoryCard
				v-for="category in categories"
				:key="category.categoryId"
				:category-id="category.categoryId"
				:title="category.title"
				:preview="category.preview"
				:token="category.token"
				:allowed="category.allowed"
			/>
		</div>
	</PageLayout>
</template>
<script>
import apiClient from '@/api';
import EducationCategoryCard from '@/shared/education-category-card/EducationCategoryCard';
import PageLayout from '@/components/page-layout/PageLayout';
import Loader from '@/shared/loader/Loader';

export default {
	name: 'Dashboard',
	components: {
		Loader,
		PageLayout,
		EducationCategoryCard,
	},
	data() {
		return {
			categories: [],
			loading: true,
			error: null,
		};
	},
	mounted() {
		this.getCategories();
	},
	methods: {
		async getCategories() {
			try {
				const response = await apiClient.getEducationCategories();
				this.categories = response.categories;
			} catch (e) {
				this.error = e.message;
			} finally {
				this.loading = false;
			}
		},
		navigateTo(link) {
			this.$router.push({
				path: link,
			});
		},
	},
};
</script>
<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.education-cards {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
</style>
